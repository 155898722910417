import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FeesComponent } from './Fees/fees.component';
import { CreateDuesComponent } from './createdues/createdues.component';
import { StudentMasterComponent } from './masters/studentmaster/studentmaster.component';
import { LeftStudentComponent } from './masters/leftstudent/leftstudent.component';
import { StudentChangeComponent } from './masters/studentchange/studentchange.component';
import { DateChangeSetComponent } from './masters/datechangeset/datechangeset.component';
import { AllowPrevTransEditComponent } from './masters/allowprevtransedit/allowprevtransedit.component';
import { StudBookCautionPdfComponent } from './pdfreports/studbookcautionpdf/studbookcautionpdf.component';
import { BoyGirlDetailListComponent } from './pdfreports/boygirldetaillist/boygirldetaillist.component';
import { SearchStudentComponent } from './masters/searchstudent/searchstudent.component';
import { TransFeesComponent } from './masters/transfees/transfees.component';
import { StudentLedgerPrevDuesComponent } from './pdfreports/studentledgerprevdues/studentledgerprevdues.component';
import { PdcTransDispComponent } from './masters/pdctransdisp/pdctransdisp.component';
import { ForLoanScholarComponent } from './pdfreports/forloanscholar/forloanscholar.component';
import { PdcTransPdfComponent } from './pdfreports/pdctranspdf/pdctranspdf.component';
import { StudentAllHistoryComponent } from './pdfreports/studentallhistory/studentallhistory.component';
import { FeeReConcilePrevComponent } from './pdfreports/feereconcileprev/feereconcileprev.component';
import { FeesAdjustComponent } from './masters/feesadjust/feesadjust.component';
import { TestreportsComponent } from './pdfreports/testreports.component';
import { LeftCreatedPdfComponent } from './pdfreports/leftcreatedpdf/leftcreatedpdf.component';
import { FeecollectionComponent } from './pdfreports/feecollection/feecollection.component';
import { FeeAdjustPdfComponent } from './pdfreports/feeadjustpdf/feeadjustpdf.component';
import { PdcTransComponent } from './masters/pdctrans/pdctrans.component';
import { DuesPaidPendingComponent } from './pdfreports/duespaidpending/duespaidpending.component';
import { FeecollectionpdfComponent } from './pdfreports/feecollectionpdf/feecollectionpdf.component';
import { FeeCollectionDatesComponent } from './pdfreports/feecollectiondates/feecollectiondates.component';
import { DuesCreatedPdfComponent } from './pdfreports/duescreatedpdf/duescreatedpdf.component';
import { OpenDuesCreatedPdfComponent } from './pdfreports/openduescreatedpdf/openduescreatedpdf.component';
import { StudentLedgerpdfComponent } from './pdfreports/studentledgerpdf/studentledgerpdf.component';
import { IndividualStudentLedgerpdfComponent } from './pdfreports/individualstudentledgerpdf/individualstudentledgerpdf.component';
import { FeeReceiptViewComponent } from './pdfreports/feereceiptview/feereceiptview.component';
import { StudentMobileDetpdfComponent } from './pdfreports/studmobiledet/studmobiledet.component';
import { FeeReConcileComponent } from './pdfreports/feereconcile/feereconcile.component';
import { LoginUserComponent } from './masters/login/login.component';
import { LogOutUserComponent } from './masters/logout/logout.component';
import { AppComponent } from './app.component';
import { MaterialDashboardComponent } from './material-dashboard/material-dashboard.component';

const routes: Routes = [
  /*{ path: '', component: TransFeesComponent, pathMatch: 'full' },*/
  //{ path: 'studentmaster', component: StudentMasterComponent },
  //{ path: 'studentchange', component: StudentChangeComponent },
  //{ path: 'student-fees', component: FeesComponent },
  //{ path: 'test-reports', component: TestreportsComponent },
  //{ path: 'fee-collection', component: FeecollectionComponent },
  //{ path: 'fee-collectionpdf', component: FeecollectionpdfComponent },
  //{ path: 'fee-collectiondates', component: FeeCollectionDatesComponent },
  //{ path: 'createdues', component: CreateDuesComponent },
  //{ path: 'dues-paidpending', component: DuesPaidPendingComponent },
  //{ path: 'student-ledgerpdf', component: StudentLedgerpdfComponent },
  //{ path: 'individualstudent-ledgerpdf', component: IndividualStudentLedgerpdfComponent },
  //{ path: 'studmobiledetpdf', component: StudentMobileDetpdfComponent },
  //{ path: 'loginuser', component: LoginUserComponent },
  //{ path: 'logoutuser', component: LogOutUserComponent },
  //{ path: 'material-dashboard', component: MaterialDashboardComponent },
  //{ path: 'feereceiptview', component: FeeReceiptViewComponent },
  //{ path: 'duescreatedpdf', component: DuesCreatedPdfComponent },
  //{ path: 'fee-reconcilepdf', component: FeeReConcileComponent },
  //{ path: 'datechangeset', component: DateChangeSetComponent },
  //{ path: 'searchstudent', component: SearchStudentComponent },
  //{ path: 'transfees', component: TransFeesComponent },

  { path: 'masters/transfees/transfees', component: TransFeesComponent },  
  { path: 'masters/studentmaster/studentmaster', component: StudentMasterComponent },
  { path: 'masters/studentchange/studentchange', component: StudentChangeComponent },
  { path: 'Fees/student-fees', component: FeesComponent },
  { path: 'pdfreports/test-reports', component: TestreportsComponent },
  { path: 'pdfreports/feecollection/fee-collection', component: FeecollectionComponent },
  { path: 'pdfreports/feecollectionpdf/fee-collectionpdf', component: FeecollectionpdfComponent },
  { path: 'pdfreports/feecollectiondates/fee-collectiondates', component: FeeCollectionDatesComponent },
  { path: 'createdues/createdues', component: CreateDuesComponent },
  { path: 'pdfreports/duespaidpending/dues-paidpending', component: DuesPaidPendingComponent },
  { path: 'pdfreports/studentledgerpdf/student-ledgerpdf', component: StudentLedgerpdfComponent },
  { path: 'pdfreports/individualstudentledgerpdf/individualstudent-ledgerpdf', component: IndividualStudentLedgerpdfComponent },
  { path: 'pdfreports/studmobiledet/studmobiledetpdf', component: StudentMobileDetpdfComponent },
  { path: 'masters/login/loginuser', component: LoginUserComponent },
  { path: 'masters/logout/logoutuser', component: LogOutUserComponent },
  { path: 'material-dashboard/material-dashboard', component: MaterialDashboardComponent },
  { path: 'pdfreports/feereceiptview/feereceiptview', component: FeeReceiptViewComponent },
  { path: 'pdfreports/duescreatedpdf/duescreatedpdf', component: DuesCreatedPdfComponent },
  { path: 'pdfreports/openduescreatedpdf/openduescreatedpdf', component: OpenDuesCreatedPdfComponent },
  { path: 'pdfreports/feereconcile/fee-reconcilepdf', component: FeeReConcileComponent },
  { path: 'masters/datechangeset/datechangeset', component: DateChangeSetComponent },
  { path: 'masters/searchstudent/searchstudent', component: SearchStudentComponent },
  { path: 'masters/feesadjust/feesadjust', component: FeesAdjustComponent },
  { path: 'masters/leftstudent/leftstudent', component: LeftStudentComponent },
  { path: 'pdfreports/leftcreatedpdf/leftcreatedpdf', component: LeftCreatedPdfComponent },
  { path: 'pdfreports/feeadjustpdf/feeadjustpdf', component: FeeAdjustPdfComponent },
  { path: 'masters/pdctrans/pdctrans', component: PdcTransComponent },
  { path: 'masters/pdctransdisp/pdctransdisp', component: PdcTransDispComponent },
  { path: 'pdfreports/pdctranspdf/pdctranspdf', component: PdcTransPdfComponent },
  { path: 'pdfreports/forloanscholar/forloanscholar', component: ForLoanScholarComponent },
  { path: 'pdfreports/studbookcautionpdf/studbookcautionpdf', component: StudBookCautionPdfComponent },
  { path: 'pdfreports/feereconcileprev/feereconcileprev', component: FeeReConcilePrevComponent },
  { path: 'pdfreports/studentledgerprevdues/studentledgerprevdues', component: StudentLedgerPrevDuesComponent },
  { path: 'pdfreports/studentallhistory/studentallhistory', component: StudentAllHistoryComponent },
  { path: 'masters/allowprevtransedit/allowprevtransedit', component: AllowPrevTransEditComponent },
  { path: 'pdfreports/boygirldetaillist/boygirldetaillist', component: BoyGirlDetailListComponent },  
  //{ path: '', redirectTo: '/sales', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],  
  exports: [RouterModule]  
})
export class AppRoutingModule { }
