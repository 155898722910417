<h3>Student Dues Register ({{GLBappFormStarEnd}})</h3>
<div class="container">
  <div *ngIf="loading!=''" class="cssload-container">
    <div class="cssload-speeding-wheel"></div>
  </div>
  <div *ngIf="loading!=''" class='fullscreenDiv'>
    <div class="center">Loading...Please Wait</div>
  </div>
  <mat-form-field appearance="standard">
    <!--<mat-label>Search</mat-label>-->
    <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
  </mat-form-field>
  <mat-radio-group [(ngModel)]="selectedRow" (change)="radioSelected()">
    <mat-radio-button [value]="0">None</mat-radio-button>
    <mat-radio-button [value]="1">Morning</mat-radio-button>
    <mat-radio-button [value]="2">Evening</mat-radio-button>
  </mat-radio-group>
  <br />
  <label>Filter Amount >=</label>
  <input type="number" placeholder="0" (change)="ChangeFilter($event)" [(ngModel)]="filerAmt" style="font-weight: bold;" width="100px" />
  <mat-radio-group [(ngModel)]="selectedCourse" (change)="radioSelectedCourse()">
    <mat-radio-button [value]="1">Standard Course Sorted</mat-radio-button>
    <mat-radio-button [value]="2">Customise Course Sorted</mat-radio-button>
  </mat-radio-group><br />
  <mat-radio-group [(ngModel)]="selectedLeft" (change)="radioSelectedLeft()">
    <mat-radio-button [value]="0">With Left Students</mat-radio-button>
    <mat-radio-button [value]="1">W/o Left Students</mat-radio-button>
    <mat-radio-button [value]="2">Only Left Students</mat-radio-button>
  </mat-radio-group>
  <br /><b>Select Course</b>
  <ng-multiselect-dropdown [placeholder]="'Courses'"
                           [settings]="dropdownSettings"
                           [data]="Courses"
                           [(ngModel)]="selectedItems"
                           (change)="onItemSelect($event)"
                           (onSelect)="onItemSelect($event)"
                           (onSelectAll)="onSelectAll($event)"
                           (onDeSelect)="onDeSelect($event)">
  </ng-multiselect-dropdown>
  <div class="export-container">
    <!--<button mat-raised-button color="primary" (click)="createPdf()">Export 1</button>-->
    <button mat-raised-button color="primary" (click)="createPdfCompress()">Export Pending Fee (Pdf + Excel)</button>
    <!--<button mat-raised-button color="primary" (click)="createPdfN()">Student Full Ledger</button>-->
    <button mat-raised-button color="primary" (click)="createPdfS1()">Student Full Ledger (Open)</button>
    <button mat-raised-button color="primary" (click)="createPdfS2()">Student Full Ledger (Download)</button>
  </div>
  <div class="mat-elevation-z8">
    <div class="cf" id="pdfTable" #pdfTable>
      <table id="ExampleTable" mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
        <ng-container matColumnDef="admno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Enrolment# </th>
          <!--<td mat-cell *matCellDef="let element"> {{element.admno}} </td>-->
          <td mat-cell *matCellDef="let row; let element" [ngClass]="{'whitetext': row.detail == 'C/Bal' || row.rNo!=1,'boldtext': row.rNo==1 }"> {{element.admno}}  </td>
        </ng-container>
        <ng-container matColumnDef="name">
          "n
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let row; let element" [ngClass]="{'whitetext': row.detail == 'C/Bal' || row.rNo!=1,'boldtext': row.rNo==1 }"> {{element.name + element.leftDate}} </td>
          <!--<td mat-cell *matCellDef="let element"> {{element.name}} </td>-->
        </ng-container>
        <ng-container matColumnDef="course">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Course </th>
          <td mat-cell *matCellDef="let row; let element" [ngClass]="{'whitetext': row.detail == 'C/Bal' || row.rNo!=1,'boldtext': row.rNo==1 }"> {{element.course}}  </td>
          <!--<td mat-cell *matCellDef="let element"> {{element.course}} </td>-->
        </ng-container>
        <ng-container matColumnDef="detail">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Detail </th>
          <td mat-cell *matCellDef="let element"> {{element.detail}} </td>
        </ng-container>
        <ng-container matColumnDef="dues">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Dues </th>
          <td mat-cell *matCellDef="let element"> {{element.dues}} </td>
        </ng-container>
        <ng-container matColumnDef="fees">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fee </th>
          <td mat-cell *matCellDef="let element"> {{element.fees}} </td>
        </ng-container>
        <ng-container matColumnDef="feepaid">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fee Paid </th>
          <td mat-cell *matCellDef="let element"> {{element.feepaid}} </td>
        </ng-container>
        <ng-container matColumnDef="adjustment">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Adjust </th>
          <td mat-cell *matCellDef="let element"> {{element.adjustment}} </td>
        </ng-container>
        <ng-container matColumnDef="balance">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Bal.Amt </th>
          <td mat-cell *matCellDef="let element"> {{element.balance}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row [ngClass]="{'make-gold': row.detail == 'C/Bal'}" *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <table id="ExampleTable" mat-table [dataSource]="dataSourcesum" matSort class="mat-elevation-z8">
        <ng-container matColumnDef="admno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Enrolment# </th>
          <!--<td mat-cell *matCellDef="let element"> {{element.admno}} </td>-->
          <td mat-cell *matCellDef="let row; let element" [ngClass]="{'whitetext': row.detail == 'C/Bal' || row.rNo!=1,'boldtext': row.rNo==1 }"> {{element.admno}}  </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let row; let element" [ngClass]="{'whitetext': row.detail == 'C/Bal' || row.rNo!=1,'boldtext': row.rNo==1 }"> {{element.name + element.leftDate}}  </td>
          <!--<td mat-cell *matCellDef="let element"> {{element.name}} </td>-->
        </ng-container>
        <ng-container matColumnDef="course">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Course </th>
          <td mat-cell *matCellDef="let row; let element" [ngClass]="{'whitetext': row.detail == 'C/Bal' || row.rNo!=1,'boldtext': row.rNo==1 }"> {{element.course}}  </td>
          <!--<td mat-cell *matCellDef="let element"> {{element.course}} </td>-->
        </ng-container>
        <ng-container matColumnDef="detail">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Detail </th>
          <td mat-cell *matCellDef="let element"> {{element.detail}} </td>
        </ng-container>
        <ng-container matColumnDef="dues">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Dues </th>
          <td mat-cell *matCellDef="let element"> {{element.dues}} </td>
        </ng-container>
        <ng-container matColumnDef="fees">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fee </th>
          <td mat-cell *matCellDef="let element"> {{element.fees}} </td>
        </ng-container>
        <ng-container matColumnDef="feepaid">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fee Paid </th>
          <td mat-cell *matCellDef="let element"> {{element.feepaid}} </td>
        </ng-container>
        <ng-container matColumnDef="adjustment">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Adjust </th>
          <td mat-cell *matCellDef="let element"> {{element.adjustment}} </td>
        </ng-container>
        <ng-container matColumnDef="balance">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Bal.Amt </th>
          <td mat-cell *matCellDef="let element"> {{element.balance}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row [ngClass]="{'make-gold': row.detail == 'C/Bal'}" *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <mat-paginator [pageSizeOptions]="[50, 100, 150, 200, 250, 300, 350]" showFirstLastButtons></mat-paginator>
  </div>
</div>
<div class="table">
  <table class="table" #tableT>
    <thead class="thead-dark">
      <tr><td style="text-align:center;font-weight:bold;font-size:18px" colspan="9"><b>{{WhichShift}}</b></td></tr>
      <tr><td style="text-align:center;font-weight:bold;font-size:18px" colspan="9"><b>A/Year:{{GLBappFormStarEnd}}</b></td></tr>      
      <tr>
        <th>SNo</th>
        <th>Enrolment#</th>
        <th>Student Name</th>
        <th>Course</th>
        <th><b>Dues</b></th>
        <th>Fee</th>
        <th>Paid</th>
        <th>Adjust</th>
        <th>Balance</th>
      </tr>
    </thead>
    <tbody>      
      <tr *ngFor="let item of listDataC;let i=index">
        <td>{{i+1}}</td>
        <td t="s"><b>{{item.admno}}</b></td>
        <td data-f-bold="true">{{item.name + item.leftDate }}</td>
        <td>{{item.course}}</td>
        <td>{{item.dues}}</td>
        <td>{{item.fees}}</td>
        <td>{{item.feepaid}}</td>
        <td>{{item.adjustment}}</td>
        <td>{{item.balance}}</td>
      </tr>
    </tbody>
  </table>
</div>
