<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html lang="en">
<head>
  <script src="https://cdn.jsdelivr.net/npm/sweetalert2@11.10.6/dist/sweetalert2.all.min.js"></script>
  <link href="https://cdn.jsdelivr.net/npm/sweetalert2@11.10.6/dist/sweetalert2.min.css" rel="stylesheet" />
</head>
<body>
  <h3>Individual Student Dues Register {{GLBappFormStarEnd}}</h3>
  <div class="container">
    <div *ngIf="loading!=''" class="cssload-container">
      <div class="cssload-speeding-wheel"></div>
    </div>
    <div *ngIf="loading!=''" class='fullscreenDiv'>
      <div class="center">Loading...Please Wait</div>
    </div>
    <mat-form-field appearance="standard">
      <label><b>Enter Enrolment No.</b></label>
      <input matInput=matInput (keyup)="applyFilter($event)" placeholder="" #input />
    </mat-form-field>
    <div class="export-container">
      <!--<button mat-raised-button color="primary" (click)="createPdf()">Export 1</button>
      <button mat-raised-button color="primary" (click)="createPdfN()">Export 2</button>-->
      <button mat-raised-button color="primary" (click)="createPdfS()">Export (PDF)</button>
    </div>
    <div class="mat-elevation-z8">
      <div class="cf" id="pdfTable" #pdfTable>
        <table id="ExampleTable" mat-table=mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
          <ng-container matColumnDef="admno">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Enrolment# </th>
            <!--<td mat-cell *matCellDef="let element"> {{element.admno}} </td>-->
            <td mat-cell *matCellDef="let row; let element" [ngClass]="{'whitetext': row.detail == 'C/Bal' || row.rNo!=1,'boldtext': row.rNo==1 }"> {{element.admno}}  </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell=mat-cell *matCellDef="let row; let element" [ngClass]="{'whitetext': row.detail == 'C/Bal' || row.rNo!=1,'boldtext': row.rNo==1 }"> {{element.name + element.leftDate}}  </td>
            <!--<td mat-cell *matCellDef="let element"> {{element.name}} </td>-->
          </ng-container>
          <ng-container matColumnDef="course">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Course </th>
            <td mat-cell *matCellDef="let row; let element" [ngClass]="{'whitetext': row.detail == 'C/Bal' || row.rNo!=1,'boldtext': row.rNo==1 }"> {{element.course}}  </td>
            <!--<td mat-cell *matCellDef="let element"> {{element.course}} </td>-->
          </ng-container>
          <ng-container matColumnDef="detail">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Detail </th>
            <td mat-cell *matCellDef="let element"> {{element.detail}} </td>
          </ng-container>
          <ng-container matColumnDef="dues">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Dues </th>
            <td mat-cell *matCellDef="let element"> {{element.dues}} </td>
          </ng-container>
          <ng-container matColumnDef="fees">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fee </th>
            <td mat-cell *matCellDef="let element"> {{element.fees}} </td>
          </ng-container>
          <ng-container matColumnDef="feepaid">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fee Paid </th>
            <td mat-cell *matCellDef="let element"> {{element.feepaid}} </td>
          </ng-container>
          <ng-container matColumnDef="adjustment">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Adjust </th>
            <td mat-cell *matCellDef="let element"> {{element.adjustment}} </td>
          </ng-container>
          <ng-container matColumnDef="balance">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Bal.Amt </th>
            <td mat-cell *matCellDef="let element"> {{element.balance}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row [ngClass]="{'make-gold': row.detail == 'C/Bal'}" *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <table id="ExampleTable" mat-table=mat-table [dataSource]="dataSourcesum" matSort class="mat-elevation-z8">
          <ng-container matColumnDef="admno">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Enrolment# </th>
            <!--<td mat-cell *matCellDef="let element"> {{element.admno}} </td>-->
            <td mat-cell *matCellDef="let row; let element" [ngClass]="{'whitetext': row.detail == 'C/Bal' || row.rNo!=1,'boldtext': row.rNo==1 }"> {{element.admno}}  </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let row; let element" [ngClass]="{'whitetext': row.detail == 'C/Bal' || row.rNo!=1,'boldtext': row.rNo==1 }"> {{element.name}}  </td>
            <!--<td mat-cell *matCellDef="let element"> {{element.name}} </td>-->
          </ng-container>
          <ng-container matColumnDef="course">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Course </th>
            <td mat-cell *matCellDef="let row; let element" [ngClass]="{'whitetext': row.detail == 'C/Bal' || row.rNo!=1,'boldtext': row.rNo==1 }"> {{element.course}}  </td>
            <!--<td mat-cell *matCellDef="let element"> {{element.course}} </td>-->
          </ng-container>
          <ng-container matColumnDef="detail">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Detail </th>
            <td mat-cell *matCellDef="let element"> {{element.detail}} </td>
          </ng-container>
          <ng-container matColumnDef="dues">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Dues </th>
            <td mat-cell *matCellDef="let element"> {{element.dues}} </td>
          </ng-container>
          <ng-container matColumnDef="fees">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fee </th>
            <td mat-cell *matCellDef="let element"> {{element.fees}} </td>
          </ng-container>
          <ng-container matColumnDef="feepaid">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fee Paid </th>
            <td mat-cell *matCellDef="let element"> {{element.feepaid}} </td>
          </ng-container>
          <ng-container matColumnDef="adjustment">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Adjust </th>
            <td mat-cell *matCellDef="let element"> {{element.adjustment}} </td>
          </ng-container>
          <ng-container matColumnDef="balance">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Bal.Amt </th>
            <td mat-cell *matCellDef="let element"> {{element.balance}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row [ngClass]="{'make-gold': row.detail == 'C/Bal'}" *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <form>
          <div *ngIf="StudentDetAll[0].admno != ''"><button style="background-color: red; color: white; border-top-left-radius: 5px; border-top-right-radius: 5px; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px;" tabindex="3" type="submit" name="deletefee" (click)="DeleteStudentFeeTran(StudentDetAll[0].admno,StudentDetAll[0].yearcode)">Delete All</button></div>
        </form>
      </div>
      <mat-paginator [pageSizeOptions]="[50, 100, 150, 200, 250, 300, 350]" showFirstLastButtons=showFirstLastButtons></mat-paginator>
    </div>
  </div>  
</body>
</html>
