<!-- Default form grid -->
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html lang="en">
<head>
  <meta charset="utf-8" />
  <meta name="viewport" content="width=device-width,initial-scale=1.0" />
  <script type="text/javascript">
    const input = document.querySelector('input');
    input.valueAsDate = new Date();
  </script>
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>
  <script src="https://ajax.googleapis.com/ajax/libs/angularjs/1.5.6/angular.min.js"></script>
  <script src="./js/Script.js"></script>
  <script type="text/javascript" src="https://ajax.googleapis.com/ajax/libs/angularjs/1.3.9/angular.min.js"></script>
  <script src="https://cdn.jsdelivr.net/npm/sweetalert2@11.10.6/dist/sweetalert2.all.min.js"></script>
  <link href="https://cdn.jsdelivr.net/npm/sweetalert2@11.10.6/dist/sweetalert2.min.css" rel="stylesheet" />
</head>
<body>
  <div class="container">
    <form>
      <div class="row">
        <div class="col">
          <h3>{{GLBappFormName}} ({{GLBappFormStarEnd}})</h3>
        </div>
      </div>
      <br /><br />
      <div class="row">
        <table id="table1" style="width:60%" name="table1">
          <tr>
            <th>#</th>            
            <th style="text-align: center; font-size:medium;">A/Year</th>
            <th style="text-align: center; font-size: medium;">Current Status</th>
            <th style="text-align: center; font-size: medium;">AllowEdit/Dis-AllowEdit</th>
          </tr>
          <tbody>
            <tr>
              <th style="text-align: center; font-weight: bold; font-size: 16px;" scope="row">1</th>
              <td style="text-align: center; font-weight: bold; font-size: 16px;">2023-2024</td>
              <td style="text-align: center; font-weight: bold; font-size: 16px;">{{ StatusCheck }}</td>
              <td style="text-align: center;">
                <div *ngIf="statusCode==9">                  
                  <button style="align-content:center; background-color: green; color: white; border-top-left-radius: 5px; border-top-right-radius: 5px; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px;" tabindex="3" type="submit" name="updateallowprevtrans" (click)="UpdateAllowPrevTrans(0, 14,'2023-2024')">Allow</button>
                </div>
                <div *ngIf="statusCode==0">                  
                  <button style="align-content: center; background-color: red; color: white; border-top-left-radius: 5px; border-top-right-radius: 5px; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px;" tabindex="3" type="submit" name="updateallowprevtrans" (click)="UpdateAllowPrevTrans(9, 14,'2023-2024')">Don't' Allow</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </form>
  </div>
</body>
</html>
