<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html lang="en">
<head>
  <meta charset="utf-8" />
  <meta name="viewport" content="width=device-width,initial-scale=1.0" />
  <script type="text/javascript">
    const input = document.querySelector('input');
    input.valueAsDate = new Date();
  </script>
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>
  <script src="https://ajax.googleapis.com/ajax/libs/angularjs/1.5.6/angular.min.js"></script>
  <script src="./js/Script.js"></script>
  <script type="text/javascript" src="https://ajax.googleapis.com/ajax/libs/angularjs/1.3.9/angular.min.js"></script>
  <script src="https://cdn.jsdelivr.net/npm/sweetalert2@11.10.6/dist/sweetalert2.all.min.js"></script>
  <link href="https://cdn.jsdelivr.net/npm/sweetalert2@11.10.6/dist/sweetalert2.min.css" rel="stylesheet" />
  <style type="text/css">
    /*html, body {
      background: #e5e5e5;
      text-align: center;
    }*/

    /* start basic spinner styles*/

    div.spinner {
      position: relative;
      width: 100px;
      height: 100px;
      display: inline-block;
    }

      div.spinner div {
        width: 12%;
        height: 26%;
        background: #000;
        position: absolute;
        left: 44.5%;
        top: 37%;
        opacity: 0;
        color: #00cc00;
        -webkit-animation: fade 1s linear infinite;
        -webkit-border-radius: 50px;
        -webkit-box-shadow: 0 0 3px rgba(0,0,0,0.2);
      }

        div.spinner div.bar1 {
          -webkit-transform: rotate(0deg) translate(0, -142%);
          -webkit-animation-delay: 0s;
        }

        div.spinner div.bar2 {
          -webkit-transform: rotate(30deg) translate(0, -142%);
          -webkit-animation-delay: -0.9167s;
        }

        div.spinner div.bar3 {
          -webkit-transform: rotate(60deg) translate(0, -142%);
          -webkit-animation-delay: -0.833s;
        }

        div.spinner div.bar4 {
          -webkit-transform: rotate(90deg) translate(0, -142%);
          -webkit-animation-delay: -0.75s;
        }

        div.spinner div.bar5 {
          -webkit-transform: rotate(120deg) translate(0, -142%);
          -webkit-animation-delay: -0.667s;
        }

        div.spinner div.bar6 {
          -webkit-transform: rotate(150deg) translate(0, -142%);
          -webkit-animation-delay: -0.5833s;
        }

        div.spinner div.bar7 {
          -webkit-transform: rotate(180deg) translate(0, -142%);
          -webkit-animation-delay: -0.5s;
        }

        div.spinner div.bar8 {
          -webkit-transform: rotate(210deg) translate(0, -142%);
          -webkit-animation-delay: -0.41667s;
        }

        div.spinner div.bar9 {
          -webkit-transform: rotate(240deg) translate(0, -142%);
          -webkit-animation-delay: -0.333s;
        }

        div.spinner div.bar10 {
          -webkit-transform: rotate(270deg) translate(0, -142%);
          -webkit-animation-delay: -0.25s;
        }

        div.spinner div.bar11 {
          -webkit-transform: rotate(300deg) translate(0, -142%);
          -webkit-animation-delay: -0.1667s;
        }

        div.spinner div.bar12 {
          -webkit-transform: rotate(330deg) translate(0, -142%);
          -webkit-animation-delay: -0.0833s;
        }

    @-webkit-keyframes fade {
      from {
        opacity: 1;
      }

      to {
        opacity: 0.25;
      }
    }

    /* end basic spinner styles*/

    /*div.container {
      display: inline-block;
      padding: 1.5em 1.5em 1.25em;
      background: rgba(0,0,0,0.8);
      -webkit-box-shadow: 1px 1px 1px #fff;
      -webkit-border-radius: 1em;
      margin: 1em;
    }*/

    /*div.container.grey {
        background: rgba(0,0,0,0.2);
      }*/

    div.container.grey.blue {
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #0a2744), color-stop(1, #064483));
    }

    div.container div.spinner {
      width: 28px;
      height: 28px;
    }

    div.container.grey div.spinner {
      width: 60px;
      height: 60px;
    }

    div.container div.spinner div {
      background: #fff;
    }

    div.labeled {
      font-family: sans-serif;
      font-size: 14px;
      margin: 0 auto;
      background: #fff;
      padding: 0.5em 0.75em 0.5em 0.5em;
      display: inline-block;
      color: #c00;
      line-height: 25px;
      -webkit-box-shadow: 0 0 3px rgba(0,0,0,0.4);
      -webkit-border-radius: 1em;
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff), color-stop(1, #ccc));
    }

      div.labeled div.spinner {
        float: left;
        vertical-align: middle;
        width: 25px;
        height: 25px;
        margin-right: 0.5em;
      }

        div.labeled div.spinner div {
          background: #c00;
        }
  </style>
</head>
<body>
  <h3>Student (Boy/Girl) List ({{GLBappFormStarEnd}})</h3>
  <div class="container">
    <div *ngIf="loading!=''" class="cssload-container">
      <div class="cssload-speeding-wheel"></div>
    </div>
    <div *ngIf="loading!=''" class='fullscreenDiv'>
      <div class="center">Loading...Please Wait</div>
    </div>
    <!--<mat-form-field appearance="standard">-->
    <!--<mat-label>Search</mat-label>-->
    <!--<input matInput (keyup)="applyFilter($event)" placeholder="" #input>
    </mat-form-field>-->
    <mat-radio-group [(ngModel)]="selectedRow" (change)="radioSelected()">
      <mat-radio-button [value]="0">None</mat-radio-button>
      <mat-radio-button [value]="1">Morning</mat-radio-button>
      <mat-radio-button [value]="2">Evening</mat-radio-button>
    </mat-radio-group>
    <br />
    <mat-radio-group [(ngModel)]="selectedBG" (change)="radioSelectedBG()">
      <mat-radio-button [value]="1">Boy's'</mat-radio-button>
      <mat-radio-button [value]="2">Girl''s</mat-radio-button>
      <mat-radio-button [value]="0">All</mat-radio-button>
    </mat-radio-group>
    <br />
    <!--<mat-radio-group [(ngModel)]="selectedCourse" (change)="radioSelectedCourse()">
      <mat-radio-button [value]="1">Standard Course Sorted</mat-radio-button>
      <mat-radio-button [value]="2">Customise Course Sorted</mat-radio-button>
    </mat-radio-group>-->
    <mat-checkbox color="primary" (change)="onCheckboxChange($event)">
      Include Left Student's
    </mat-checkbox>
    <br /><b>Select Course</b>
    <ng-multiselect-dropdown [placeholder]="'Courses'"
                             [settings]="dropdownSettings"
                             [data]="Courses"
                             [(ngModel)]="selectedItems"
                             (change)="onItemSelect($event)"
                             (onSelect)="onItemSelect($event)"
                             (onSelectAll)="onSelectAll($event)"
                             (onDeSelect)="onDeSelect($event)">
    </ng-multiselect-dropdown>
    <div class="export-container">
      <button mat-raised-button color="primary" (click)="createPdf()">Export (pdf)</button>
      <!--<button mat-raised-button color="primary" (click)="downloadPdf()">Export List (Pdf + Excel)</button>-->      
    </div>
    <div class="mat-elevation-z8">
      <div class="cf" id="pdfTable" #pdfTable>
        <table id="ExampleTable" mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
          <ng-container matColumnDef="sno">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>S#</th>
            <td mat-cell *matCellDef="let element"> {{element.sno}} </td>
          </ng-container>
          <ng-container matColumnDef="admno">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Enrolment#</th>
            <td mat-cell *matCellDef="let element"> {{element.admno}} </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Student Name</th>
            <td mat-cell *matCellDef="let element">
              <input type="text" name="studentname" (change)="onChangeStudent($event)" style="font-weight:bold; font-size:14px; text-transform:uppercase" value="{{element.studentName}}" />
              <br />
              <button style="align-content: center; background-color: #1b4f72; color: white; border-top-left-radius: 5px; border-top-right-radius: 5px; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px;" tabindex="3" type="submit" name="updateallowprevtrans" (click)="UpdateStudentName(element.admno)">Update</button>
            </td>
          </ng-container>
          <ng-container matColumnDef="fathername">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Father's Name</th>
            <td mat-cell *matCellDef="let element">
              <input type="text" name="fathername" (change)="onChangeFather($event)" style="font-size: 12px; text-transform: uppercase" value="{{element.fatherName}}" />
              <br />
              <button style="align-content: center; background-color: #1b4f72; color: white; border-top-left-radius: 5px; border-top-right-radius: 5px; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px;" tabindex="3" type="submit" name="updateallowprevtrans" (click)="UpdateFatherName(element.admno)">Update</button>
            </td>
          </ng-container>
          <ng-container matColumnDef="course">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Course</th>
            <td mat-cell *matCellDef="let element">
              {{element.course}}
              <ng-container *ngIf="element.tcflagn == 1">
                <br />
                <span style="font-size:16px;color:red">(Student Left)</span>
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="mobile">
	  	      <th mat-header-cell *matHeaderCellDef mat-sort-header=mat-sort-header>Mobile</th>
            <td mat-cell *matCellDef="let element">
              <input type="number" name="mobileno" (change)="onChangeMobile($event)" style="font-size: 12px;" value="{{element.mobileNo}}" />
              <br />
              <button style="align-content: center; background-color: #1b4f72; color: white; border-top-left-radius: 5px; border-top-right-radius: 5px; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px;" tabindex="3" type="submit" name="updateallowprevtrans" (click)="UpdateMobileNo(element.admno)">Update</button>
            </td>
	        </ng-container>
          <ng-container matColumnDef="gender">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Boy/Girl/All</th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.gender == 'M'">
                <span class="label boy">Boy</span>
              </ng-container>
              <ng-container *ngIf="element.gender == 'F'">
                <span class="label girl">Girl</span>
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="changeTo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Update To</th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.gender == 'M'">
                <button style="align-content: center; background-color: #145a32; color: white; border-top-left-radius: 5px; border-top-right-radius: 5px; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px;" tabindex="3" type="submit" name="updateallowprevtrans" (click)="UpdateBoyGirl(element.admno,'F')">To Girl</button>
              </ng-container>
              <ng-container *ngIf="element.gender == 'F'">
                <button style="align-content: center; background-color: #ba4a00; color: white; border-top-left-radius: 5px; border-top-right-radius: 5px; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px;" tabindex="3" type="submit" name="updateallowprevtrans" (click)="UpdateBoyGirl(element.admno,'M')">To Boy</button>
              </ng-container>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
      <mat-paginator [pageSizeOptions]="[50, 100, 150, 200, 250, 300, 350]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</body>
</html>
