import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { debug } from 'console';

@Injectable({
  providedIn: 'root'
})

export class BoyGirlDetailListService {
  readonly baseURL = sessionStorage.getItem('NbaseURLs');
  constructor(private http: HttpClient) { }

  //ViewStudLedgerDet(Admno: string, YearCode: Number): Observable<any> {
  ViewBoyGirlList(ShiftId: number, YearCode: number, CSCode: string, selectedBG: number, ShowWithOutLeft: number): Observable<any> {
    debugger;
    //console.log(this.baseURL + '/ViewStudFeeDetailsDetAlls?ShiftId=' + ShiftId + '&&YearCode=' + YearCode + '&&CSCode=' + CSCode)
    return this.http.get<any>(this.baseURL + '/ViewBoyGirlList?ShiftId=' + ShiftId + '&&YearCode=' + YearCode + '&&CSCode=' + CSCode + '&&selectedBG=' + selectedBG + '&&ShowWithOutLeft=' + ShowWithOutLeft)
    .pipe(retry(1), catchError(this.handleError));
  }

  //ViewStudLedgerDetC(ShiftId: number, YearCode: number, CSCode: string, FilterAmt: number): Observable<any> {
  //  debugger;
  //  //console.log(this.baseURL + '/ViewStudFeeDetailsDetAllsC?ShiftId=' + ShiftId + '&&YearCode=' + YearCode + '&&CSCode=' + CSCode + '&&FilterAmt=' + FilterAmt);
  //  return this.http.get<any>(this.baseURL + '/ViewStudFeeDetailsDetAllsC?ShiftId=' + ShiftId + '&&YearCode=' + YearCode + '&&CSCode=' + CSCode + '&&FilterAmt=' + FilterAmt)
  //    .pipe(retry(1), catchError(this.handleError));
  //}

  //ViewStudLedgerDetSum(ShiftId: number, YearCode: number, CSCode: string): Observable<any> {
  //  debugger;
  //  return this.http.get<any>(this.baseURL + '/ViewStudFeeDetailsDetAllSum?ShiftId=' + ShiftId + '&&YearCode=' + YearCode + '&&CSCode=' + CSCode)
  //    .pipe(retry(1), catchError(this.handleError));
  //}

  GetCourses(): Observable<any> {
    debugger;
    return this.http.get<any>(this.baseURL + '/GetCourses?YearCode=' + parseInt(sessionStorage.getItem('YearCode')))
      .pipe(retry(1), catchError(this.handleError));
  }

  GetCoursesC(): Observable<any> {
    debugger;
    return this.http.get<any>(this.baseURL + '/GetCoursesC?YearCode=' + parseInt(sessionStorage.getItem('YearCode')))
      .pipe(retry(1), catchError(this.handleError));
  }

  //ViewStudFeeDetailsDet(Admno: string,YearCode: Number): Observable<any> {
  //  debugger;
  //  return this.http.get<any>(this.baseURL + '/ViewStudFeeDetailsDet?Admno=' + Admno + '&&YearCode=' + YearCode);
  //}
  //ViewStudFeeDetailsDetAll(): Observable<any> {
  //  debugger;
  //  return this.http.get<any>(this.baseURL + '/ViewStudFeeDetailsDetAll')
  //  .pipe(retry(1), catchError(this.handleError));
  //}

  GenderUpdate(admno: string, gender: string): Observable<any> {
    debugger;
    return this.http.get<any>(this.baseURL + '/GenderUpdate?Admno=' + admno + '&&Gender=' + gender)
      .pipe(retry(1), catchError(this.handleError));
  }

  UpdateStudentName(admno: string, studentName: string): Observable<any> {
    debugger;
    return this.http.get<any>(this.baseURL + '/UpdateStudentName?Admno=' + admno + '&&studentName=' + studentName)
      .pipe(retry(1), catchError(this.handleError));
  }

  UpdateFatherName(admno: string, fatherName: string): Observable<any> {
    debugger;
    return this.http.get<any>(this.baseURL + '/UpdateFatherName?Admno=' + admno + '&&fatherName=' + fatherName)
      .pipe(retry(1), catchError(this.handleError));
  }

  UpdateMobileNo(admno: string, MobileNo: string): Observable<any> {
    debugger;
    return this.http.get<any>(this.baseURL + '/UpdateMobileNo?Admno=' + admno + '&&MobileNo=' + MobileNo)
      .pipe(retry(1), catchError(this.handleError));
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}
